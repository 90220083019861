import { useContext } from 'react'
import { Box, CheckboxCircle, Chip, DonutChart, Grid, Hidden, Link, Typography, useTheme } from '@hermes/web-components'
import { StyledChip } from '../../styles'
import useBuildTopStats from '../../hooks/useBuildTopStats'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { SearchReviewsContext } from '../../../../providers/SearchReviews'
import { themedColor } from '../../../profiles/styles'
import { EndorsementsChartProps } from './types'

const EndorsementsChart = ({ isBookingAvailable, statistic }: EndorsementsChartProps) => {
  const {
    state: { filters }
  } = useContext(SearchReviewsContext)

  const theme = useTheme()
  const { chipsTextColor, endorsementsBackground } = themedColor()

  const translate = useTranslateMessage()

  const {
    stats: { topStats, totalStatCount },
    onSelectKeyword
  } = useBuildTopStats(statistic)

  const TopStats = () =>
    !!topStats.length && (
      <Grid container mb={2} direction="column" sx={{ px: { lg: 2 } }}>
        <Typography
          textTransform={theme.custom?.RatingBar?.sectionTitle?.textTransform}
          color="text.dark"
          variant="body2"
          component="h3"
          sx={{ whiteSpace: 'nowrap', pb: { md: 1, xs: 1.5 } }}
        >
          {translate('reviews.total_recommendations_count_for', {
            total: totalStatCount
          })}
        </Typography>
        <Grid container item xs direction="row" wrap="nowrap" alignItems="center">
          <DonutChart width={100} height={100} data={topStats} thickness={6} />
          <Grid ml={1} container maxWidth="60%" item alignContent="start" spacing={1}>
            {topStats.map((keyword) => {
              if (!keyword) {
                return null
              }

              const { name, value, variant, color } = keyword
              const isKeywordSelected = filters.keyword === name

              return (
                <Grid
                  container
                  item
                  key={`${name}-${value}-${variant}`}
                  xs={12}
                  alignItems="center"
                  spacing={0.5}
                  wrap="nowrap"
                >
                  <Box
                    sx={{
                      marginRight: 1,
                      backgroundColor: color,
                      width: 8,
                      height: 8,
                      flexShrink: 0,
                      borderRadius: '50%'
                    }}
                  />
                  <StyledChip
                    variant={variant}
                    onClick={() => {
                      onSelectKeyword(name)
                    }}
                    clickable
                    sx={{
                      '.MuiChip-label': {
                        paddingLeft: isKeywordSelected ? 0 : '8px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                        ...theme.typography.caption
                      }
                    }}
                    component="a"
                    href="#reviews"
                    label={
                      <>
                        {isKeywordSelected && (
                          <CheckboxCircle
                            sx={{
                              marginRight: isKeywordSelected ? '2px' : 0,
                              width: '18.33px'
                            }}
                          />
                        )}
                        <span
                          style={{
                            maxWidth: '100%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {name}
                        </span>
                        {!isKeywordSelected && `(${value})`}
                      </>
                    }
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    )

  return (
    <>
      {isBookingAvailable && (
        <>
          <Hidden lgUp implementation="css">
            <TopStats />
          </Hidden>
          <Hidden lgDown implementation="css">
            <Grid
              container
              py="16px"
              px={{ lg: 2 }}
              direction="column"
              sx={{ borderTop: '1px solid #D5DEF9', borderBottom: '1px solid #D5DEF9' }}
            >
              <Typography variant="body2" component="h3" fontWeight={600} whiteSpace="nowrap" color="#112971" mb="8px">
                {totalStatCount} recommendations for:
              </Typography>
              <Box display="flex" flexWrap="wrap" gap="8px">
                {topStats?.filter(Boolean)?.map((keyword) => {
                  if (!keyword) {
                    return null
                  }

                  const { name, value, variant } = keyword

                  const isKeywordSelected = filters.keyword === name

                  return (
                    <Chip
                      component={Link}
                      href="#reviews"
                      key={`${name}-${value}-${variant}`}
                      onClick={() => onSelectKeyword(name)}
                      label={
                        <Box display="flex" alignItems="center" gap="8px">
                          {isKeywordSelected && <CheckboxCircle sx={{ width: '22px', height: '22px' }} />}
                          <Typography
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            variant={isKeywordSelected ? 'body2' : 'caption'}
                            color={isKeywordSelected ? '#1A3586' : chipsTextColor}
                            fontWeight={500}
                          >
                            {keyword.name}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        ':hover': {
                          backgroundColor: isKeywordSelected ? 'rgba(0, 229, 208, 0.3)' : '#CCFAF6'
                        },
                        backgroundColor: isKeywordSelected ? 'rgba(0, 229, 208, 0.2)' : endorsementsBackground,
                        '.MuiChip-label': { paddingLeft: isKeywordSelected ? '2px' : '8px' },
                        cursor: 'pointer',
                        border: `0.5px ${chipsTextColor} solid`
                      }}
                    />
                  )
                })}
              </Box>
            </Grid>
          </Hidden>
        </>
      )}
      {!isBookingAvailable && !!topStats.length && <TopStats />}
    </>
  )
}

export default EndorsementsChart
