import { styledComponent, DonutChart, Grid, Paper, Chip, Box } from '@hermes/web-components'
import { RevealsContainer } from '../profiles/ProfileModal/SpecialistPracticeBookingCard/Layout'

export const StyledRevealsContainer = styledComponent(RevealsContainer)(({ theme }) => ({
  '& > button, & > a': {
    flex: 1
  },
  padding: '16px',
  [theme.breakpoints.up('lg')]: {
    borderTop: `1px solid ${theme.palette.grey[600]}`
  },
  [theme.breakpoints.down('sm')]: {
    borderTop: `1px solid ${theme.palette.grey[600]}`,
    position: 'fixed',
    bottom: 0,
    zIndex: 9,
    left: 0,
    backgroundColor: theme.palette.common.white
  }
}))

export const DonutChartContainer = styledComponent(DonutChart)({
  '&.donut': {
    minWidth: 120,
    minHeight: 120,
    padding: '10px',
    width: 100,
    height: 100,
    marginLeft: '-16px'
  }
})

export const RootWrapper = styledComponent(Paper, {
  shouldForwardProp: (propName: string) => propName !== 'elementOffset'
})<{
  elementOffset: number
}>(({ theme, elementOffset }) => ({
  boxShadow: '0px 6px 10px rgba(2, 3, 3, 0.03), 0px 1px 18px rgba(2, 3, 3, 0.02), 0px 3px 5px rgba(2, 3, 3, 0.04)',
  maxWidth: 415,
  marginLeft: 'auto',
  width: '100%',
  color: theme.palette.text.darkNavy,
  border: `1px solid ${theme.palette.secondary.light}`,
  [theme.breakpoints.down('lg')]: {
    backgroundColor: 'inherit',
    border: 'none',
    maxWidth: 'none',
    boxShadow: 'none'
  },
  [theme.breakpoints.up('lg')]: {
    position: 'sticky',
    top: elementOffset,
    zIndex: 1051
  }
}))

export const SectionWrapper = styledComponent(Grid)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.secondary.light}`,
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  [theme.breakpoints.down('xs')]: {
    px: 0
  }
}))

export const StyledChip = styledComponent(Chip)<{ component?: string; href?: string }>(() => ({
  border: 1,
  color: 'inherit',
  maxWidth: '100%'
}))

export const RootContainer = styledComponent(Box, {
  shouldForwardProp: (propName: string) => propName !== 'height'
})<{
  height: string
}>(({ theme, height }) => ({
  width: 'calc(100% - 32px)',
  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    top: 0,
    right: 0,
    height,
    maxWidth: 415
  }
}))

export const StarsContainer = styledComponent(Box)(() => ({
  display: 'flex',
  justifyContent: 'center'
}))
