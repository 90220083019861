import { Box, CheckboxCircle, DonutChart, Grid, Typography, useTheme } from '@hermes/web-components'
import { StyledChip } from '../../styles'
import useBuildTopStats from '../../hooks/useBuildTopStats'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { useContext } from 'react'
import { SearchReviewsContext } from '../../../../providers/SearchReviews'
import { EndorsementsChartProps } from './types'

const EndorsementsChart = ({ statistic }: EndorsementsChartProps) => {
  const {
    state: { filters }
  } = useContext(SearchReviewsContext)

  const theme = useTheme()

  const translate = useTranslateMessage()

  const {
    stats: { topStats, totalStatCount },
    onSelectKeyword
  } = useBuildTopStats(statistic)

  return (
    <>
      {!!topStats.length && (
        <Grid
          container
          direction="column"
          sx={{
            pt: { sm: '15px' },
            pb: { lg: '0', sm: '15px' },
            width: 'auto',
            borderTop: { lg: `1px solid ${theme.palette.grey[600]}` }
          }}
        >
          <Typography variant="body2" component="h3" sx={{ color: '#11245A', whiteSpace: 'nowrap', pb: 2 }}>
            {translate(
              'reviews.total_recommendations_count_for',
              {
                total: totalStatCount
              },
              { bold: { fontWeight: 500, fontSize: '13px' } }
            )}
          </Typography>
          <Grid container item xs direction="row" wrap="nowrap" alignItems="center">
            <DonutChart width={120} height={120} data={topStats} thickness={5} />
            <Grid ml={1} container maxWidth="60%" item alignContent="start" spacing={1}>
              {topStats.map((keyword) => {
                if (!keyword) {
                  return null
                }

                const { name, value, variant, color } = keyword
                const isKeywordSelected = filters.keyword === name

                return (
                  <Grid
                    container
                    item
                    key={`${name}-${value}-${variant}`}
                    xs={12}
                    alignItems="center"
                    spacing={0.5}
                    wrap="nowrap"
                  >
                    <Box
                      sx={{
                        marginRight: 1,
                        backgroundColor: color,
                        width: 8,
                        height: 8,
                        flexShrink: 0,
                        borderRadius: '50%'
                      }}
                    />
                    <StyledChip
                      variant={variant}
                      onClick={() => {
                        onSelectKeyword(name)
                      }}
                      clickable
                      sx={{
                        '.MuiChip-label': {
                          paddingLeft: isKeywordSelected ? 0 : '8px',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '3px',
                          ...theme.typography.caption
                        }
                      }}
                      component="a"
                      href="#reviews"
                      label={
                        <>
                          {isKeywordSelected && (
                            <CheckboxCircle
                              sx={{
                                marginRight: isKeywordSelected ? '2px' : 0,
                                width: '18.33px'
                              }}
                            />
                          )}
                          <span
                            style={{
                              maxWidth: '100%',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {name}
                          </span>
                          {!isKeywordSelected && `(${value})`}
                        </>
                      }
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default EndorsementsChart
