import { useRouter } from 'next/router'
import { useMediaQuery, useTheme } from '@hermes/web-components'
import { StyledRevealsContainer } from '../../../../styles'
import ContactDescription from '../../../../../profiles/ProfileModal/SpecialistPracticeBookingCard/ContactDescription'
import { DetailsLink } from '../../../../../RevealLink'
import useTranslateMessage from '../../../../../../hooks/useTranslateMessage'
import useRatingBarConfig from '../../../../hooks/useRatingBarConfig'
import { RequestTypes } from '../../../../../../constants/profile'
import { TENANTS } from '../../../../../../constants/global'
import { ActionPanelProps } from '../../types'
import { useContext } from 'react'
import { ProfileContext } from '../../../../../../providers/AppData/Profile'

const revealBookKey = 'bookAppointment.reveal.book'

const ActionPanel = ({ isClinic, contacts, onClickReveal, onClickBookAppointment }: ActionPanelProps) => {
  const { locale } = useRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const translate = useTranslateMessage()
  const { phoneIcon, emailIcon, externalBookingLinkIcon } = useRatingBarConfig()
  const { specialist, practice } = useContext(ProfileContext)

  const hasExtraStyles = locale === TENANTS.WPA
  const emailHref = isMobile ? `mailto:${contacts?.[0]?.email}?subject=${translate('email.subject')}` : undefined

  return (
    <StyledRevealsContainer>
      {isClinic ? (
        <>
          {!!contacts[0].phone && (
            <ContactDescription
              contactValue={contacts[0].phone}
              description={translate('phone_no')}
              href={`tel:${contacts[0].phone}`}
              revealName={isMobile ? translate('call') : contacts[0].phone}
              slug={contacts[0].slug}
              active
              skipReveal={isMobile}
              icon={phoneIcon}
              revealTitle={translate('call')}
              requestType={RequestTypes.PHONE}
              onClickLinkHandler={() =>
                onClickReveal(
                  isMobile ? 'prf_pg_cont_popup_reveal_tel' : 'prf_pg_cont_popup_tel_link',
                  'Call',
                  contacts[0].contactName as string,
                  contacts[0].externalId
                )
              }
              onClickRevealHandler={() =>
                onClickReveal(
                  'prf_pg_cont_popup_reveal_tel',
                  'Call',
                  contacts[0].contactName as string,
                  contacts[0].externalId
                )
              }
            />
          )}
          {!!contacts[0].email && (
            <ContactDescription
              contactValue={contacts[0].email}
              description={translate('email')}
              href={emailHref}
              revealLink={emailHref}
              revealName={isMobile ? translate('email') : contacts[0].email}
              slug={contacts[0].slug}
              active
              skipReveal={isMobile}
              icon={emailIcon}
              revealTitle={translate('bookAppointment.reveal.email')}
              requestType={RequestTypes.EMAIL}
              onClickLinkHandler={() => {
                !isMobile && navigator.clipboard.writeText(contacts[0].email || '')
                onClickReveal(
                  'prf_pg_cont_popup_reveal_email',
                  'Email',
                  contacts[0].contactName as string,
                  contacts[0].externalId
                )
              }}
              onClickRevealHandler={() =>
                onClickReveal(
                  'prf_pg_cont_popup_reveal_email',
                  'Email',
                  contacts[0].contactName as string,
                  contacts[0].externalId
                )
              }
            />
          )}
          {!!contacts[0].externalBookingLink && (
            <ContactDescription
              contactValue={contacts[0].externalBookingLink as string}
              active={true}
              description={translate('book')}
              href={contacts[0].externalBookingLink}
              specialistId={specialist?.id}
              practiceId={practice?.id}
              bookingButton
              skipReveal
              revealName={''}
              slug={practice?.slug}
              revealLink={contacts[0].externalBookingLink}
              icon={
                <>
                  {externalBookingLinkIcon}
                  <p>{translate(revealBookKey)}</p>
                </>
              }
              revealTitle={translate(revealBookKey)}
              requestType={RequestTypes.EXTERNAL_BOOKING_LINK}
              onClickLinkHandler={() =>
                onClickReveal(
                  'prf_pg_book_popup_booking_link',
                  'Book',
                  contacts[0].contactName as string,
                  contacts[0].externalId
                )
              }
            />
          )}
        </>
      ) : (
        <>
          {contacts.some(({ phone }) => !!phone) && (
            <DetailsLink
              color={theme.custom?.SpecialistPage?.trustScore?.textColor}
              type="primary"
              sx={{ flex: 1 }}
              onClick={() => onClickBookAppointment('call', 'Call')}
              isWPA={hasExtraStyles}
            >
              {phoneIcon}
              <p>{translate('call')}</p>
            </DetailsLink>
          )}
          {contacts.some(({ email }) => !!email) && (
            <DetailsLink
              color={theme.custom?.SpecialistPage?.trustScore?.textColor}
              type="primary"
              sx={{ flex: 1 }}
              onClick={() => onClickBookAppointment('email', 'Email')}
              isWPA={hasExtraStyles}
            >
              {emailIcon}
              <p>{translate('email')}</p>
            </DetailsLink>
          )}
          {contacts.some(({ externalBookingLink }) => !!externalBookingLink) && (
            <DetailsLink
              color={theme.custom?.SpecialistPage?.trustScore?.textColor}
              type="primary"
              sx={{ flex: 1 }}
              onClick={() => onClickBookAppointment('book', 'Book')}
              isWPA={hasExtraStyles}
            >
              {externalBookingLinkIcon}
              <p>{translate(revealBookKey)}</p>
            </DetailsLink>
          )}
        </>
      )}
    </StyledRevealsContainer>
  )
}

export default ActionPanel
