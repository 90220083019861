import { useContext } from 'react'
import {
  Box,
  Grid,
  Hidden,
  Link,
  NoEndorsementsIcon,
  NoReviewIcon,
  NoReviewsImageIcon,
  Typography,
  useTheme
} from '@hermes/web-components'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { RATING_TYPE } from '../../constants'
import { AppDataContext } from '../../../../providers/AppData'
import { SectionWrapper } from '../../styles'
import { RecentReviewComponentProps } from './types'
import { ProfilePeerRecommendation, ProfileReview } from '../../../../types'
import { useDateTimeCorrector } from '../../../../hooks/useDatetimeCorrector'
import { useWpaTenant } from '../../../../hooks/useWpaTenant'
import { truncateString } from '../../../../utils/strings'
import { isOlderThanNMonth } from '../../../../utils/date'
import RecommenderComponent from '../Recommender'

type SectionWrapperComponentProps = {
  title: string
  latestReview?: ProfileReview | ProfilePeerRecommendation
  recommender?: ProfilePeerRecommendation['recommender']
  language: string
  noReviewIcon: JSX.Element
  noReviewTitle: string
  outDatedTitle?: string
  href: string
}

const SectionWrapperComponent = ({
  title,
  latestReview,
  recommender,
  language,
  noReviewIcon,
  noReviewTitle,
  outDatedTitle,
  href
}: SectionWrapperComponentProps) => {
  const dateTime = useDateTimeCorrector(latestReview?.createdAt)
  const theme = useTheme()
  const { isWPA } = useWpaTenant()

  const textLatestReview = latestReview && 'text' in latestReview ? latestReview.text : latestReview?.recommendation
  const cuttedLatestReview = !!textLatestReview && truncateString(textLatestReview)
  const isOlderThanSixMonth = isOlderThanNMonth(6, latestReview?.createdAt)

  return (
    <SectionWrapper container direction="column">
      <Grid container justifyContent="space-between" mb={1}>
        <Typography sx={{ ...theme?.custom?.RatingBar?.sectionTitle }} variant="body2" component="h3">
          {title}
        </Typography>
        {!!latestReview && !(isOlderThanSixMonth && outDatedTitle) && (
          <Typography variant="subtitle2" component="p" fontWeight={400} color="text.secondary">
            {dateTime}
          </Typography>
        )}
      </Grid>
      {!!latestReview && latestReview.id && !(isOlderThanSixMonth && outDatedTitle) ? (
        <>
          <Typography
            component={Link}
            href={href}
            variant="body1"
            sx={{
              maxHeight: 45,
              maxWidth: '100%',
              overflow: 'hidden',
              textDecoration: 'inherit',
              ...theme.custom.SpecialistPage.ratingBar.reviewText
            }}
          >
            {cuttedLatestReview}
          </Typography>
          {!!recommender && <RecommenderComponent recommender={recommender} language={language} />}
        </>
      ) : (
        <Box display="flex">
          {!isWPA && noReviewIcon}
          <Typography>{isOlderThanSixMonth && outDatedTitle ? outDatedTitle : noReviewTitle}</Typography>
        </Box>
      )}
    </SectionWrapper>
  )
}

const Default = ({ isUnclaimed, type, isBasic, latestReview, peerRecommendation }: RecentReviewComponentProps) => {
  const { language } = useContext(AppDataContext)

  const translate = useTranslateMessage()

  const isClinic = type === RATING_TYPE.CLINIC
  const isSpecialist = type === RATING_TYPE.SPECIALIST

  return isUnclaimed ? (
    <SectionWrapper sx={{ pt: 4.5, pb: 3, display: 'flex', justifyContent: 'center' }}>
      <Grid container direction="column" spacing={2}>
        <Grid item display="flex" justifyContent="center">
          <NoReviewsImageIcon />
        </Grid>
        <Grid item display="flex" justifyContent="center">
          <Typography color="text.black900">
            {translate(isClinic ? 'reviews.no_reviews_practice' : 'reviews.no_reviews_specialist')}
          </Typography>
        </Grid>
      </Grid>
    </SectionWrapper>
  ) : (
    <Hidden lgDown>
      <SectionWrapperComponent
        title={translate('reviews.recent_patient_review')}
        latestReview={!isBasic ? latestReview : undefined}
        language={language}
        noReviewIcon={<NoReviewIcon sx={{ width: 48, height: 48, mr: 2 }} />}
        noReviewTitle={translate(isClinic ? 'reviews.no_reviews_practice' : 'reviews.not_collecting_specialist')}
        outDatedTitle={translate(isClinic ? 'no_review_over_6_month_clinic' : 'no_review_over_6_month')}
        href="#reviews"
      />
      {isSpecialist && (
        <SectionWrapperComponent
          title={translate('peerRecommendations.latest_recommendation')}
          latestReview={peerRecommendation}
          recommender={peerRecommendation?.recommender}
          language={language}
          noReviewIcon={<NoEndorsementsIcon sx={{ width: 48, height: 48, mr: 2 }} />}
          noReviewTitle={translate('peerRecommendations.not_collecting_specialist')}
          href="#endorsements"
        />
      )}
    </Hidden>
  )
}
export default Default
