import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useTheme } from '@hermes/web-components'
import { DetailsLink } from '../../../../../RevealLink'
import useTranslateMessage from '../../../../../../hooks/useTranslateMessage'
import useRatingBarConfig from '../../../../hooks/useRatingBarConfig'
import { ActionPanelProps } from '../../types'
import { TENANTS } from '../../../../../../constants/global'

const SpecialistPanel = ({
  contacts,
  onClickBookAppointment,
  onlineBookingAvailable
}: Pick<ActionPanelProps, 'contacts' | 'onClickBookAppointment' | 'onlineBookingAvailable'>) => {
  const { locale } = useRouter()

  const theme = useTheme()
  const translate = useTranslateMessage()

  const { phoneIcon, externalBookingLinkIcon } = useRatingBarConfig()

  const phoneAvailable = useMemo(() => contacts.some(({ phone }) => !!phone), [contacts])
  const emailAvailable = useMemo(() => contacts.some(({ email }) => !!email), [contacts])
  const externalBookingAvailable = useMemo(
    () => contacts.some(({ externalBookingLink }) => !!externalBookingLink),
    [contacts]
  )

  const hasExtraStyles = locale === TENANTS.WPA

  return (
    <>
      {phoneAvailable || emailAvailable ? (
        <DetailsLink
          color={theme.custom?.SpecialistPage?.trustScore?.textColor}
          type="primary"
          sx={{ flex: 1 }}
          onClick={() => onClickBookAppointment('contact', 'Contact')}
          isWPA={hasExtraStyles}
        >
          {phoneIcon}
          <p>{translate('contact')}</p>
        </DetailsLink>
      ) : null}
      {externalBookingAvailable || onlineBookingAvailable ? (
        <DetailsLink
          color={theme.custom?.SpecialistPage?.trustScore?.textColor}
          type="primary"
          sx={{ flex: 1 }}
          onClick={() => onClickBookAppointment('book', 'Book')}
          isWPA={hasExtraStyles}
        >
          {externalBookingLinkIcon}
          <p>{translate(onlineBookingAvailable ? 'bookAppointment.book_online' : 'bookAppointment.reveal.book')}</p>
        </DetailsLink>
      ) : null}
    </>
  )
}

export default SpecialistPanel
