import { useState, useContext, useEffect, useMemo, useCallback } from 'react'
import { Typography, Grid, useTheme, Stack, Box, Hidden } from '@hermes/web-components'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { AppDataContext } from '../../../../providers/AppData'
import { SearchReviewsContext } from '../../../../providers/SearchReviews'
import useRatingBarConfig from '../../hooks/useRatingBarConfig'
import { RatingBarProps, TopKeywordType } from '../../types'
import useBuildTopStats from '../../hooks/useBuildTopStats'
import StarsComponent from '../../components/StarsComponent'
import { RATING_TYPE } from '../../constants'
import ActionPanel from '../../components/ActionPanel'
import EndorsementsChart from '../../components/EndorsementsChart'
import RecentReviewComponent from '../../components/RecentReviewComponent'
import ReviewStats from '../../components/ReviewStats'

function Default(props: RatingBarProps) {
  const {
    profileName,
    isBasic = false,
    averageRating,
    reviewsTotal,
    externalIdProfile,
    peerRecommendation,
    peerRecommendationsCount,
    type,
    onBookAppointment,
    statistic = [],
    latestReview,
    review,
    hideBookAppointment = false,
    isUnclaimed = false,
    contacts = [],
    slug,
    bookingCalendarComponent = null,
    onlineBookingAvailable
  } = props

  const { language } = useContext(AppDataContext)
  const {
    state: { keywordNames }
  } = useContext(SearchReviewsContext)

  const translate = useTranslateMessage()

  const theme = useTheme()

  const { displayNewBadge, displayEndorsementsChart, displayRecentReview } = useRatingBarConfig(props)

  const { onSelectKeyword } = useBuildTopStats(statistic)

  const isClinic = type === RATING_TYPE.CLINIC
  const isOnlineBookingAvailable = !!bookingCalendarComponent
  const displayStarsComponent = !(isBasic || isUnclaimed || !averageRating)

  const contactsAvailable = !hideBookAppointment && contacts?.length >= 1
  const showPanel = contactsAvailable && !isOnlineBookingAvailable
  const showCalendar = isOnlineBookingAvailable

  const [expanded, setExpanded] = useState(true)
  const toggleExpandedContent = () => setExpanded((prev) => !prev)

  const [activeKeyword, setActiveKeyword] = useState<TopKeywordType | null>(null)

  const onClickBookAppointment = useCallback(
    (button: string, label: string) => {
      if (onBookAppointment) {
        onBookAppointment(button)
      }
      window.dataLayer.push({
        event: 'prf_pg_cont_button',
        cont_prf_name: profileName,
        cont_prf_type: type === RATING_TYPE.CLINIC ? 'Practice' : 'Specialist',
        cont_interaction_label: label,
        cont_prf_uuid: externalIdProfile
      })
    },
    [externalIdProfile, onBookAppointment, profileName, type]
  )

  const onClickReveal = useCallback(
    (event: Event, label: string, targetName: string, targetUuid: string) => {
      window.dataLayer.push({
        event,
        cont_prf_name: profileName,
        cont_prf_type: type === RATING_TYPE.CLINIC ? 'Practice' : 'Specialist',
        cont_enquired_at_name: targetName,
        cont_enquired_at_uuid: targetUuid,
        cont_interaction_label: label,
        cont_prf_uuid: externalIdProfile
        // todo: fix typing
      } as any)
    },
    [externalIdProfile, profileName, type]
  )

  useEffect(() => {
    const activeKeywordFromStorage = localStorage.getItem(`activeKeyword-${slug}`)

    if (statistic?.length && activeKeywordFromStorage && activeKeywordFromStorage?.length) {
      const activeKeywordFromStatistic = statistic.find((x) => x.tagName[language] === activeKeywordFromStorage)
      const activeKeywordFromKeywordNames = keywordNames?.some((keyword) => keyword.name === activeKeywordFromStorage)

      if (activeKeywordFromStatistic && activeKeywordFromKeywordNames) {
        setActiveKeyword({
          name: activeKeywordFromStatistic.tagName[language],
          variant: 'aqua',
          value: activeKeywordFromStatistic.tagCount,
          color: 'rgba(0, 229, 208, 0.6)',
          hover: '#00E5D0'
        })
      }
    }
    localStorage.removeItem(`activeKeyword-${slug}`)
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistic])

  useEffect(() => {
    if (activeKeyword) {
      onSelectKeyword(activeKeyword.name)
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKeyword])

  const actionPanelComponent = useMemo(
    () => (
      <ActionPanel
        isClinic={isClinic}
        onlineBookingAvailable={onlineBookingAvailable}
        onClickBookAppointment={onClickBookAppointment}
        onClickReveal={onClickReveal}
        contacts={contacts}
      />
    ),
    [contacts, isClinic, onClickBookAppointment, onClickReveal, onlineBookingAvailable]
  )

  return (
    <>
      <Grid sx={{ py: 2, px: { xs: 0, lg: 2 }, pb: 0 }} container justifyContent="space-between">
        <Typography variant="body2" component="h3" sx={theme.custom.SpecialistPage.ratingBar.title}>
          {translate('reviews.trust_rating')}
        </Typography>
        {displayNewBadge && (
          <Stack direction="row" sx={{ backgroundColor: '#FFEFEF', p: 0.5, px: 1, borderRadius: 16 }}>
            <Typography variant="body2" sx={{ ml: 0.5 }}>
              {translate('new')}
            </Typography>
          </Stack>
        )}
      </Grid>

      <ReviewStats
        hideRealData={isBasic || isUnclaimed}
        reviewsTotal={reviewsTotal}
        averageRating={averageRating}
        peerRecommendationsCount={peerRecommendationsCount}
      />

      {displayStarsComponent && (
        <StarsComponent
          expanded={expanded}
          averageRating={averageRating}
          review={review}
          toggleExpandedContent={toggleExpandedContent}
        />
      )}

      {displayEndorsementsChart && !!statistic?.length ? (
        <EndorsementsChart
          activeKeyword={activeKeyword}
          statistic={statistic}
          isBookingAvailable={isOnlineBookingAvailable}
        />
      ) : null}

      {displayRecentReview && (
        <RecentReviewComponent
          isUnclaimed={isUnclaimed}
          isBasic={isBasic}
          type={type as RATING_TYPE}
          latestReview={latestReview}
          peerRecommendation={peerRecommendation}
        />
      )}

      {showCalendar ? (
        <Box component={Hidden} smDown implementation="css">
          {bookingCalendarComponent}
        </Box>
      ) : null}

      {contactsAvailable ? (
        <Box component={Hidden} xsmUp implementation="css">
          {actionPanelComponent}
        </Box>
      ) : null}

      {showPanel ? (
        <Box component={Hidden} xsmDown implementation="css">
          {actionPanelComponent}
        </Box>
      ) : null}
    </>
  )
}

export default Default
