import { ActionPanelProps } from './types'
import React from 'react'
import DefaultVariant from './variants/default'
import MinimalVariant from './variants/minimal'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'

const ActionPanel = (props: ActionPanelProps) => {
  const { newPersistentPanelEnabled } = useFeatureFlags()

  if (newPersistentPanelEnabled) {
    return <MinimalVariant {...props} />
  }
  return <DefaultVariant {...props} />
}

export default ActionPanel
