import { useContext, useMemo } from 'react'
import { useMediaQuery, useTheme } from '@hermes/web-components'
import ContactDescription from '../../../../../profiles/ProfileModal/SpecialistPracticeBookingCard/ContactDescription'
import { RequestTypes } from '../../../../../../constants/profile'
import { ActionPanelProps } from '../../types'
import useTranslateMessage from '../../../../../../hooks/useTranslateMessage'
import useRatingBarConfig from '../../../../hooks/useRatingBarConfig'
import { ProfileContext } from '../../../../../../providers/AppData/Profile'

const PracticePanel = ({ contacts, onClickReveal }: Pick<ActionPanelProps, 'contacts' | 'onClickReveal'>) => {
  const theme = useTheme()
  const translate = useTranslateMessage()
  const { specialist, practice } = useContext(ProfileContext)

  const { phoneIcon, emailIcon, externalBookingLinkIcon } = useRatingBarConfig()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const firstContact = useMemo(() => contacts[0], [contacts])

  const emailHref = isMobile ? `mailto:${contacts?.[0]?.email}?subject=${translate('email.subject')}` : undefined

  return (
    <>
      {!!firstContact.phone && (
        <ContactDescription
          contactValue={firstContact.phone}
          description={translate('phone_no')}
          href={`tel:${firstContact.phone}`}
          revealName={isMobile ? translate('call') : firstContact.phone}
          slug={firstContact.slug}
          active
          skipReveal={isMobile}
          icon={phoneIcon}
          revealTitle={translate('call')}
          requestType={RequestTypes.PHONE}
          onClickLinkHandler={() =>
            onClickReveal(
              isMobile ? 'prf_pg_cont_popup_reveal_tel' : 'prf_pg_cont_popup_tel_link',
              'Call',
              firstContact.contactName as string,
              firstContact.externalId
            )
          }
          onClickRevealHandler={() =>
            onClickReveal(
              'prf_pg_cont_popup_reveal_tel',
              'Call',
              firstContact.contactName as string,
              firstContact.externalId
            )
          }
        />
      )}
      {!!firstContact.email && (
        <ContactDescription
          contactValue={firstContact.email}
          description={translate('email')}
          href={emailHref}
          revealLink={emailHref}
          revealName={isMobile ? translate('email') : firstContact.email}
          slug={firstContact.slug}
          active
          skipReveal={isMobile}
          icon={emailIcon}
          revealTitle={translate('bookAppointment.reveal.email')}
          requestType={RequestTypes.EMAIL}
          onClickLinkHandler={() => {
            !isMobile && navigator.clipboard.writeText(firstContact.email || '')
            onClickReveal(
              'prf_pg_cont_popup_reveal_email',
              'Email',
              firstContact.contactName as string,
              firstContact.externalId
            )
          }}
          onClickRevealHandler={() =>
            onClickReveal(
              'prf_pg_cont_popup_reveal_email',
              'Email',
              firstContact.contactName as string,
              firstContact.externalId
            )
          }
        />
      )}
      {!!firstContact.externalBookingLink && (
        <ContactDescription
          contactValue={firstContact.externalBookingLink as string}
          active={true}
          description={translate('book')}
          href={firstContact.externalBookingLink}
          specialistId={specialist?.id}
          practiceId={practice?.id}
          bookingButton
          skipReveal
          revealName={''}
          slug={practice?.slug}
          revealLink={firstContact.externalBookingLink}
          icon={
            <>
              {externalBookingLinkIcon}
              <p>{translate('bookAppointment.reveal.book')}</p>
            </>
          }
          revealTitle={translate('bookAppointment.reveal.book')}
          requestType={RequestTypes.EXTERNAL_BOOKING_LINK}
          onClickLinkHandler={() =>
            onClickReveal(
              'prf_pg_book_popup_booking_link',
              'Book',
              firstContact.contactName as string,
              firstContact.externalId
            )
          }
        />
      )}
    </>
  )
}

export default PracticePanel
