import React from 'react'
import { Box, Grid, Hidden, Link, Rating, styledComponent, Typography, useTheme } from '@hermes/web-components'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { RATING_TYPE } from '../../constants'
import { RecentReviewComponentProps } from './types'
import { ProfilePeerRecommendation, ProfileReview } from '../../../../types'
import { useDateTimeCorrector } from '../../../../hooks/useDatetimeCorrector'
import { truncateString } from '../../../../utils/strings'
import { isOlderThanNMonth } from '../../../../utils/date'

const Wrapper = styledComponent(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[600]}`,
  paddingTop: '16px'
}))

type SectionWrapperComponentProps = {
  title: string
  latestReview?: ProfileReview | ProfilePeerRecommendation
  outDatedTitle?: string
  href: string
}

const SectionWrapperComponent = ({ title, latestReview, outDatedTitle, href }: SectionWrapperComponentProps) => {
  const dateTime = useDateTimeCorrector(latestReview?.createdAt)
  const theme = useTheme()

  const textLatestReview = latestReview && 'text' in latestReview ? latestReview.text : latestReview?.recommendation
  const latestReviewCut = !!textLatestReview && truncateString(textLatestReview)
  const isOlderThanSixMonth = isOlderThanNMonth(6, latestReview?.createdAt)
  const reviewRating = latestReview && 'averageRating' in latestReview ? latestReview?.averageRating : null

  return (
    <Grid container direction="column">
      {!!latestReview && !isOlderThanSixMonth ? (
        <Grid container justifyContent="space-between" mb={1}>
          <Typography sx={{ fontWeight: '500' }} variant="body2" component="h3">
            {title}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
            {reviewRating && (
              <Box>
                <Rating value={reviewRating} size={'xxs'} readOnly />
              </Box>
            )}
            <Typography variant="subtitle2" component="p" fontWeight={400} color="text.secondary">
              {dateTime}
            </Typography>
          </Box>
        </Grid>
      ) : null}
      {!!latestReview && latestReview.id && !isOlderThanSixMonth ? (
        <>
          <Typography
            component={Link}
            href={href}
            variant="body1"
            sx={{
              maxHeight: 45,
              maxWidth: '100%',
              overflow: 'hidden',
              textDecoration: 'inherit',
              ...theme.custom.SpecialistPage.ratingBar.reviewText
            }}
          >
            {latestReviewCut}
          </Typography>
        </>
      ) : null}
      {isOlderThanSixMonth && outDatedTitle ? (
        <Typography sx={{ fontWeight: '500' }} variant="body2" component="h3">
          {outDatedTitle}
        </Typography>
      ) : null}
    </Grid>
  )
}

const Minimal = ({ isUnclaimed, type, isBasic, latestReview, peerRecommendation }: RecentReviewComponentProps) => {
  const translate = useTranslateMessage()

  const isSpecialist = type === RATING_TYPE.SPECIALIST
  const showReview = !isBasic && !isUnclaimed && !!latestReview?.id
  const showEndorsement = isSpecialist && !isUnclaimed && !!peerRecommendation?.recommendation

  return showReview || showEndorsement ? (
    <Wrapper>
      <Box component={Hidden} lgDown implementation="css">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {showReview ? (
            <SectionWrapperComponent
              title={translate('reviews.latest_patient_review', {}, { bold: { fontWeight: 500, fontSize: '13px' } })}
              latestReview={latestReview}
              outDatedTitle={translate('no_review_over_6_month_general')}
              href="#reviews"
            />
          ) : null}
          {showEndorsement ? (
            <SectionWrapperComponent
              title={translate(
                'peerRecommendations.latest_skill_endorsement',
                {},
                { bold: { fontWeight: 500, fontSize: '13px' } }
              )}
              latestReview={peerRecommendation}
              outDatedTitle={translate('no_endorsement_over_6_month')}
              href="#endorsements"
            />
          ) : null}
        </Box>
      </Box>
    </Wrapper>
  ) : null
}
export default Minimal
