import React from 'react'
import MinimalVariant from './minimal'
import DefaultVariant from './default'
import { RecentReviewComponentProps } from './types'
import { Variants } from '../StarsComponent/types'

const RecentReviewComponent = ({ variant, ...props }: RecentReviewComponentProps & { variant?: Variants }) => {
  if (variant === Variants.minimal) {
    return <MinimalVariant {...props} />
  }

  return <DefaultVariant {...props} />
}
export default RecentReviewComponent
