import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { ActionPanelProps } from '../../types'
import SpecialistPanel from './SpecialistPanel'
import PracticePanel from './PracticePanel'
import { ButtonsPanelWrapper } from './styles'
import { StyledRevealsContainer } from '../../../../styles'
import { TENANTS } from '../../../../../../constants/global'

const ActionPanel = ({
  isClinic,
  contacts,
  onClickReveal,
  onClickBookAppointment,
  onlineBookingAvailable
}: ActionPanelProps) => {
  const { locale } = useRouter()
  // refer to an old designed container for WPA
  const Container = useMemo(() => (locale === TENANTS.WPA ? StyledRevealsContainer : ButtonsPanelWrapper), [locale])

  return (
    <Container>
      {isClinic ? (
        <PracticePanel contacts={contacts} onClickReveal={onClickReveal} />
      ) : (
        <SpecialistPanel
          contacts={contacts}
          onlineBookingAvailable={onlineBookingAvailable}
          onClickBookAppointment={onClickBookAppointment}
        />
      )}
    </Container>
  )
}

export default ActionPanel
