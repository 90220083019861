import { Grid, Typography } from '@hermes/web-components'
import dayjs from 'dayjs'
import React from 'react'
import { PATH_TO_UNAVAILABLE_IMG } from '../../../../../constants/global'
import { SharedLinkData } from '../../../../../types'
import { ContentWrapper, IntroductionVideoWrapper } from './styles'
import NextImage from 'next/image'
import { doctifyCDNLoader } from '../../../../../utils/imageLoaders'

const IntroductionVideo = ({ image, description, title, date, duration, createdAt }: SharedLinkData) => (
  <IntroductionVideoWrapper>
    <NextImage
      src={image || PATH_TO_UNAVAILABLE_IMG}
      layout="fill"
      style={{
        position: 'absolute',
        objectFit: 'cover',
        zIndex: 0
      }}
      loading="lazy"
      alt={title || ''}
      loader={doctifyCDNLoader}
    />
    <Grid
      container
      position="absolute"
      zIndex={1}
      justifyContent="space-between"
      flexDirection="column"
      wrap="nowrap"
      top={0}
      height="100%"
    >
      <Grid item padding="19px 16px"></Grid>
      <ContentWrapper container color="common.white">
        <Grid container gap="8px" flexDirection="column">
          <Typography
            variant="h4"
            fontWeight={900}
            fontSize="27.03px"
            lineHeight="35.14px"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: '3',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {title}
          </Typography>
          <Typography
            fontSize="14px"
            lineHeight="19.6px"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: '2',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {description}
          </Typography>
        </Grid>
        <Grid container maxWidth="max-content" alignItems="end" gap="10px">
          {duration && (
            <Typography variant="body2" lineHeight="20px">
              {duration}
            </Typography>
          )}
          <Typography variant="body2" lineHeight="20px">
            {dayjs(date || createdAt).format('DD MMMM YYYY')}
          </Typography>
        </Grid>
      </ContentWrapper>
    </Grid>
  </IntroductionVideoWrapper>
)

export default IntroductionVideo
