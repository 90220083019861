import React, { useState, useContext, useEffect } from 'react'
import { Hidden, Box } from '@hermes/web-components'
import { AppDataContext } from '../../../../providers/AppData'
import { SearchReviewsContext } from '../../../../providers/SearchReviews'
import useRatingBarConfig from '../../hooks/useRatingBarConfig'
import { RatingBarProps, TopKeywordType } from '../../types'
import useBuildTopStats from '../../hooks/useBuildTopStats'
import StarsComponent from '../../components/StarsComponent'
import { RATING_TYPE } from '../../constants'
import ActionPanel from '../../components/ActionPanel'
import EndorsementsChart from '../../components/EndorsementsChart'
import RecentReviewComponent from '../../components/RecentReviewComponent'
import { StarsContainer } from '../../styles'
import { Variants } from '../../components/StarsComponent/types'

function Minimal(props: RatingBarProps) {
  const {
    profileName,
    isBasic = false,
    averageRating,
    externalIdProfile,
    peerRecommendation,
    type,
    onBookAppointment,
    statistic = [],
    latestReview,
    review,
    hideBookAppointment = false,
    isUnclaimed = false,
    contacts = [],
    slug,
    onlineBookingAvailable
  } = props

  const { language } = useContext(AppDataContext)
  const {
    state: { keywordNames }
  } = useContext(SearchReviewsContext)

  const { displayEndorsementsChart, displayRecentReview } = useRatingBarConfig(props)

  const { onSelectKeyword } = useBuildTopStats(statistic)

  const isClinic = type === RATING_TYPE.CLINIC
  const displayStarsComponent = !(isBasic || isUnclaimed || !averageRating)

  const [activeKeyword, setActiveKeyword] = useState<TopKeywordType | null>(null)

  const onClickBookAppointment = (button: string, label?: string) => {
    if (onBookAppointment) {
      onBookAppointment(button)
    }
    window.dataLayer.push({
      event: 'prf_pg_cont_button',
      cont_prf_name: profileName,
      cont_prf_type: type === RATING_TYPE.CLINIC ? 'Practice' : 'Specialist',
      cont_interaction_label: label,
      cont_prf_uuid: externalIdProfile
    })
  }

  const onClickReveal = (event: Event, label: string, targetName: string, targetUuid: string) => {
    window.dataLayer.push({
      event,
      cont_prf_name: profileName,
      cont_prf_type: type === RATING_TYPE.CLINIC ? 'Practice' : 'Specialist',
      cont_enquired_at_name: targetName,
      cont_enquired_at_uuid: targetUuid,
      cont_interaction_label: label,
      cont_prf_uuid: externalIdProfile
      // todo: fix typing
    } as any)
  }

  useEffect(() => {
    const activeKeywordFromStorage = localStorage.getItem(`activeKeyword-${slug}`)

    if (statistic?.length && activeKeywordFromStorage && activeKeywordFromStorage?.length) {
      const activeKeywordFromStatistic = statistic.find((x) => x.tagName[language] === activeKeywordFromStorage)
      const activeKeywordFromKeywordNames = keywordNames?.some((keyword) => keyword.name === activeKeywordFromStorage)

      if (activeKeywordFromStatistic && activeKeywordFromKeywordNames) {
        setActiveKeyword({
          name: activeKeywordFromStatistic.tagName[language],
          variant: 'aqua',
          value: activeKeywordFromStatistic.tagCount,
          color: 'rgba(0, 229, 208, 0.6)',
          hover: '#00E5D0'
        })
      }
    }
    localStorage.removeItem(`activeKeyword-${slug}`)
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistic])

  useEffect(() => {
    if (activeKeyword) {
      onSelectKeyword(activeKeyword.name)
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKeyword])

  return (
    <>
      <Box component={Hidden} smDown implementation="css" sx={{ '&:only-child': { paddingBottom: '20px' } }}>
        <StarsContainer sx={{ paddingTop: '20px' }}>
          {displayStarsComponent ? (
            <StarsComponent averageRating={averageRating} review={review} variant={Variants.minimal} />
          ) : (
            <StarsComponent averageRating={0} review={null} variant={Variants.minimal} />
          )}
        </StarsContainer>
      </Box>

      {displayEndorsementsChart && (
        <EndorsementsChart
          activeKeyword={activeKeyword}
          statistic={statistic}
          isBookingAvailable={onlineBookingAvailable}
          variant={Variants.minimal}
        />
      )}

      {displayRecentReview && (!!latestReview || !!peerRecommendation?.recommendation) ? (
        <Box component={Hidden} lgDown implementation="css">
          <RecentReviewComponent
            isUnclaimed={isUnclaimed}
            isBasic={isBasic}
            type={type as RATING_TYPE}
            latestReview={latestReview}
            peerRecommendation={peerRecommendation}
            variant={Variants.minimal}
          />
        </Box>
      ) : null}

      {!hideBookAppointment && contacts?.length >= 1 ? (
        <ActionPanel
          isClinic={isClinic}
          onlineBookingAvailable={onlineBookingAvailable}
          onClickBookAppointment={onClickBookAppointment}
          onClickReveal={onClickReveal}
          contacts={contacts}
        />
      ) : null}
    </>
  )
}

export default Minimal
