import { useEffect } from 'react'

const useScrollEndEffect = (callback: () => void, dependencies: unknown[]) => {
  useEffect(() => {
    let isScrolled = false
    let timeoutId: NodeJS.Timeout | null = null

    const onScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(() => {
        if (!isScrolled) {
          callback()
          isScrolled = true
        }
      }, 100)
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      isScrolled = true

      window.removeEventListener('scroll', onScroll)
    }
  }, [callback, dependencies])
}

export default useScrollEndEffect
