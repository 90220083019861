import React, { ReactNode, useContext, useState, useEffect, useMemo } from 'react'
import {
  Grid,
  Typography,
  HTMLSlot,
  Tooltip,
  InfoOutlinedIcon,
  Avatar,
  useTheme,
  useMediaQuery,
  Box,
  ShieldManIcon,
  MedalIcon,
  HeartPulseProfileIcon,
  styledComponent,
  NotepadIcon,
  StarDocumentIcon,
  Chip,
  MedalWithStarIcon,
  PublicationsIcon,
  ArrowRightIcon,
  SxProps,
  SvgIcon,
  TooltipProps
} from '@hermes/web-components'
import { useVisibilityComponents } from '../../../hooks/useVisibilityComponents'
import { ProfileContext } from '../../../providers/AppData/Profile'
import dayjs from 'dayjs'
import ExpandableList, { ToggleButton } from '../../ExapndableList'
import AreasOfExpertiseChart from '../../AreasOfExpertiseChart'
import { AppDataContext } from '../../../providers/AppData'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { currencySymbol } from '../../../utils/price'
import {
  ProfileKeyword,
  SpecialistRegistrationBody,
  SpecialistLanguage,
  ProfileInsurer,
  SpecialistProfile,
  Statistic,
  HealthCheck,
  SharedLinkData,
  PracticeFacility
} from '../../../types/index'
import PracticeHealthChecks from '../practice/PracticeHealthChecks'
import { useKeywordsMapper } from '../../../hooks/useKeywordsMapper'
import EducationIcon from '../../Icons/EducationIcon'
import FacilitiesAtGlanceIcon from '../../Icons/FacilitiesAtGlanceIcon'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import OtherTopRated from '../specialist/OtherTopRated'
import { NOFOLLOW_EXCEPTION } from '../Media/Posts/Card'
import NextLink from 'next/link'
import { useWpaTenant } from '../../../hooks/useWpaTenant'
import {
  themedColor,
  StyledSectionHeaderWrapper,
  StyledDivider,
  StyledSpecialtiesWrapper,
  PublicationWrapper,
  List
} from '../styles'
import { TENANTS } from '../../../constants/global'
import ReadMore from '../CollapsibleComponent'

const ImproveListingForm = dynamic(() => import('./ImproveListingForm'))

type ProfileAboutProps = {
  isBasic?: boolean
  isUnclaimed?: boolean
  about: string
  keywords: ProfileKeyword[]
  registrationBodies?: SpecialistRegistrationBody[]
  languages?: SpecialistLanguage[]
  insurers: ProfileInsurer[]
  consultationFees?: SpecialistProfile['consultationFees']
  statistic: Statistic[]
  qualifications?: string
  isClinic?: boolean
  name?: string
  isEntry?: boolean
  otherSpecialistsButtonText?: string
  otherSpecialistSearchUrl?: string
  nearBy?: SpecialistProfile[]
  healthChecks?: HealthCheck[]
  publications: SharedLinkData[]
  pinnedFacilities?: PracticeFacility[]
  isImproveListingVisible?: boolean
}

const flexAlign = 'flex-start'

interface SectionHeaderProps {
  icon?: ReactNode
  fontWeight?: string
  gutterBottom?: boolean
  children: ReactNode
  label?: string | number
  ml?: string
  mr?: string
  mb?: string
  sx?: SxProps
  onClickChip?: () => void
}

const CustomTooltip = styledComponent(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': theme.custom.SpecialistPage.tooltip
}))

// todo: fix complexity
// eslint-disable-next-line complexity
function ProfileAbout({
  about,
  keywords,
  registrationBodies,
  insurers,
  languages,
  consultationFees,
  statistic,
  isBasic = false,
  name = '',
  isUnclaimed = false,
  qualifications,
  isClinic = false,
  otherSpecialistsButtonText,
  otherSpecialistSearchUrl,
  healthChecks,
  nearBy,
  isEntry = false,
  publications,
  pinnedFacilities,
  isImproveListingVisible = false
}: ProfileAboutProps) {
  const { language, isRtl } = useContext(AppDataContext)
  const { practice, specialist } = useContext(ProfileContext)
  const { titleTextTransform, aboutSectionTitleColor, aboutSectionTitleFontWeight } = themedColor()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const translate = useTranslateMessage()
  const { locale } = useRouter()
  const { isWPA } = useWpaTenant()
  const { subSpecialties, conditionAndTreatments, specialties } = useKeywordsMapper(keywords, isClinic)
  const visibilityComponents = useVisibilityComponents({ practice, specialist })

  const [showMorePublications, setShowMorePublications] = useState(false)
  const [conditionKeywords, setConditionKeywords] = useState<ProfileKeyword[]>([])
  const [procedureKeywords, setProcedureKeywords] = useState<ProfileKeyword[]>([])

  const isUniversitySectionVisible = useMemo(
    () => !!specialist?.education?.universities?.some(({ enabled }) => enabled),
    [specialist?.education?.universities]
  )
  const sortedUniversities = useMemo(
    () => specialist?.education?.universities?.sort((a, b) => a.sortOrder - b.sortOrder),
    [specialist?.education?.universities]
  )

  useEffect(() => {
    const cks: ProfileKeyword[] = []
    const pks: ProfileKeyword[] = []

    conditionAndTreatments
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .forEach((item) => {
        if (item.keywordType === 'condition') {
          return cks.push(item)
        }
        if (item.keywordType === 'procedure') {
          return pks.push(item)
        }
      })

    setConditionKeywords(cks)
    setProcedureKeywords(pks)
  }, [conditionAndTreatments])

  const handleShowMorePublications = () => setShowMorePublications(true)

  const pinnedFacilitiesItemsLength = useMemo(() => pinnedFacilities?.length || 0, [pinnedFacilities?.length])

  const SectionHeader = ({
    children,
    fontWeight = '600',
    gutterBottom = false,
    icon,
    label,
    ml,
    mr,
    mb,
    sx,
    onClickChip
  }: SectionHeaderProps) => (
    <StyledSectionHeaderWrapper
      container
      sx={{
        marginLeft: ml,
        marginRight: mr,
        marginBottom: mb,
        ...(gutterBottom ? { marginBottom: '0.35rem' } : {}),
        ...sx
      }}
    >
      {icon}
      <Typography
        textTransform={titleTextTransform}
        color={aboutSectionTitleColor}
        variant="subtitle1"
        component="h3"
        fontWeight={fontWeight}
        sx={theme.custom.SpecialistPage.about.subHeader}
      >
        {children}
      </Typography>
      {label && (
        <Chip
          onClick={onClickChip}
          sx={{
            fontSize: '10.53px',
            fontWeight: '500',
            lineHeight: '15.795px',
            color: 'text.dark',
            padding: '1px 8px',
            span: {
              padding: 0
            }
          }}
          label={label}
          variant="blue"
        />
      )}
    </StyledSectionHeaderWrapper>
  )

  const showAbout = useMemo(() => about?.length > 10, [about?.length])

  return (
    <Grid container direction="column">
      <Box id="about" sx={{ width: 1 }}>
        {showAbout && (
          <Grid item>
            <Typography
              sx={theme.custom?.SpecialistPage?.about?.title}
              variant="h2"
              component="h2"
              color={aboutSectionTitleColor}
            >
              {translate('about.title', { name })}
            </Typography>
            {isWPA ? <ReadMore text={about} maxLength={500} /> : <HTMLSlot>{about}</HTMLSlot>}
          </Grid>
        )}
        {isImproveListingVisible && <ImproveListingForm />}
        {!!pinnedFacilitiesItemsLength && (
          <>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid item>
              <SectionHeader icon={<FacilitiesAtGlanceIcon />} sx={{ alignItems: 'center !important' }}>
                {translate('facilities_at_glance')}
              </SectionHeader>
              <Typography color="text.black900" mt="4px">
                {translate('facilities-at-glance.subtitle')}
              </Typography>
              <Box
                display="flex"
                columnGap="24px"
                rowGap={{ xs: '24px', sm: '8px' }}
                mt="18px"
                flexDirection={{ xs: 'column', sm: 'row' }}
                flexWrap="wrap"
                alignItems={{ xs: 'start', sm: 'center' }}
              >
                {pinnedFacilities?.map(({ Facility, value = {} }, index) => (
                  <Box key={`pinned-facility-${Facility.name}-${index}`} display="flex" alignItems="center" gap="4px">
                    <SvgIcon sx={{ width: '24px', height: '24px' }}>
                      <use href="#tick-icon" />
                    </SvgIcon>
                    {value?.count ? `${value.count} ` : ''}
                    {Facility.name[language]}
                    {'paid' in value && ` (${value.paid ? translate('paid') : translate('free')})`}
                  </Box>
                ))}
                <NextLink href="#services-facilities">
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      fontWeight: 600,
                      color: '#4C5870',
                      textDecoration: 'underline'
                    }}
                  >
                    {translate('view_more')}
                    <ArrowRightIcon style={{ width: '16px', height: '16px' }} color="#11245A" />
                  </span>
                </NextLink>
              </Box>
            </Grid>
          </>
        )}
        {(isEntry || isUnclaimed) && !!nearBy?.length && (
          <Grid item sx={{ width: 1 }}>
            {showAbout || !!pinnedFacilitiesItemsLength || isImproveListingVisible ? (
              <Grid item>
                <StyledDivider />
              </Grid>
            ) : null}
            <OtherTopRated
              specialists={nearBy}
              headerText={translate('other.specialsts.headerText')}
              buttonText={otherSpecialistsButtonText}
              isRtl={isRtl}
              shortButtonText={translate('other.specialsts.shortButtonText')}
              buttonLink={otherSpecialistSearchUrl}
            />
          </Grid>
        )}
        {!!statistic?.length && !isUnclaimed && !isBasic && (
          <>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid item>
              <AreasOfExpertiseChart
                statistic={statistic}
                title={
                  <Grid container alignItems="center">
                    <Grid item>
                      <SectionHeader
                        fontWeight={aboutSectionTitleFontWeight}
                        icon={
                          <ShieldManIcon
                            sx={{
                              width: '16px',
                              stroke: '#051851'
                            }}
                          />
                        }
                      >
                        {translate('profile.areas_of_expertise')}
                      </SectionHeader>
                    </Grid>
                    <Grid item>
                      <CustomTooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={4000}
                        title={translate(
                          isClinic ? 'areas_of_expertise_tooltip' : 'reviews.total_recommendations_hint'
                        )}
                      >
                        <Box display="flex" alignItems="center">
                          <InfoOutlinedIcon sx={{ fontSize: 16, ml: 1, color: '#112971' }} />
                        </Box>
                      </CustomTooltip>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          </>
        )}
        {(!!subSpecialties?.length || !!specialties?.length) && (
          <>
            <Grid id="specialties" item>
              <StyledDivider />
            </Grid>
            <StyledSpecialtiesWrapper
              gap="13px"
              flexWrap={isMobile ? 'wrap' : 'nowrap'}
              container
              alignItems="flex-start"
            >
              {isClinic && !!specialties?.length && (
                <Grid
                  container
                  sx={{
                    alignItems: flexAlign,
                    maxWidth: specialties?.length ? '50%' : '100%'
                  }}
                  flexDirection="column"
                >
                  <SectionHeader
                    mr="116px"
                    mb={isMobile ? undefined : '16px'}
                    fontWeight={aboutSectionTitleFontWeight}
                    icon={
                      <MedalWithStarIcon
                        sx={{
                          width: '12px',
                          fill: 'none'
                        }}
                      />
                    }
                  >
                    {translate('profile.specialties')}
                  </SectionHeader>
                  <ExpandableList
                    itemGutterBottom={!isMobile}
                    hideBullets={!isMobile}
                    items={specialties}
                    valueKey="name"
                    locale={language}
                  />
                </Grid>
              )}
              {!!subSpecialties?.length && (
                <Grid
                  container
                  sx={{
                    alignItems: flexAlign,
                    // eslint-disable-next-line no-nested-ternary
                    maxWidth: specialties?.length ? (isClinic ? '50%' : '100%') : '100%',
                    ...(isMobile || (isClinic && !!specialties?.length)
                      ? {}
                      : {
                          display: 'grid',
                          gap: '13px',
                          gridTemplateColumns: '1fr 1fr'
                        })
                  }}
                  /* eslint-disable-next-line no-nested-ternary */
                  flexDirection={specialties?.length ? (isClinic ? 'column' : 'row') : 'row'}
                >
                  <SectionHeader
                    mr="116px"
                    mb={isMobile ? undefined : '16px'}
                    fontWeight={aboutSectionTitleFontWeight}
                    icon={
                      <MedalIcon
                        sx={{
                          width: '12px',
                          fill: 'none'
                        }}
                      />
                    }
                  >
                    {translate(`profile.subspecialties${locale === 'au' ? '-au' : ''}`)}
                  </SectionHeader>
                  <ExpandableList
                    itemGutterBottom={!isMobile}
                    hideBullets={locale !== TENANTS.WPA}
                    items={subSpecialties}
                    valueKey="name"
                    locale={language}
                  />
                </Grid>
              )}
            </StyledSpecialtiesWrapper>
          </>
        )}
        {!!conditionAndTreatments.length && (
          <>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid item>
              <SectionHeader
                fontWeight={aboutSectionTitleFontWeight}
                icon={
                  <HeartPulseProfileIcon
                    sx={{
                      width: '18px',
                      fill: 'none'
                    }}
                  />
                }
              >
                {translate('profile.all_conditions_and_treatments')}
              </SectionHeader>
              <Box mt="16px" display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                <ExpandableList
                  sx={{ flex: 1 }}
                  items={conditionKeywords}
                  valueKey="name"
                  locale={language}
                  hideBullets={locale !== TENANTS.WPA}
                  itemGutterBottom={!isMobile}
                  limit={isMobile ? 5 : 10}
                  subtitle={isWPA ? translate('profile.conditions') : undefined}
                />
                <ExpandableList
                  sx={{ flex: 1 }}
                  items={procedureKeywords}
                  valueKey="name"
                  locale={language}
                  hideBullets={locale !== TENANTS.WPA}
                  itemGutterBottom={!isMobile}
                  limit={isMobile ? 5 : 10}
                  subtitle={isWPA ? translate('profile.treatments') : undefined}
                />
              </Box>
            </Grid>
          </>
        )}
        {!!registrationBodies?.length && !isUnclaimed && (
          <>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              sx={{
                ...(isMobile
                  ? {}
                  : {
                      display: 'grid',
                      gap: '15px',
                      gridTemplateColumns: '1fr 1fr'
                    })
              }}
              mt={2}
            >
              <SectionHeader
                icon={
                  <NotepadIcon
                    sx={{
                      width: '13px',
                      fill: 'none'
                    }}
                  />
                }
                mr="106px"
                mb="16px"
                fontWeight={aboutSectionTitleFontWeight}
              >
                {translate('profile.registered_with')}
              </SectionHeader>
              <Box>
                {registrationBodies.map((rb) => (
                  <Grid
                    key={`registration-body-${rb.id}`}
                    container
                    sx={{ mb: { md: 2, xs: 1.5 }, contentVisibility: 'auto', containIntrinsicHeight: '500px' }}
                  >
                    {!!rb.iconUri.trim() && (
                      <Grid sx={{ width: 48, height: 48 }} item>
                        <Avatar src={rb.iconUri} />
                      </Grid>
                    )}
                    <Grid item>
                      <Typography variant="body1" fontWeight={500} color="text.black900">
                        {rb.name}
                      </Typography>
                      {rb.registrationNumber.length > 1 && (
                        <Typography variant="body2" color="text.black900">
                          No. {rb.registrationNumber}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Grid>
          </>
        )}
        {isUniversitySectionVisible && (
          <>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid item>
              <SectionHeader fontWeight={aboutSectionTitleFontWeight} icon={<EducationIcon />}>
                {translate('education')}
              </SectionHeader>
              {sortedUniversities?.map(({ externalId, endDate, fieldOfStudy, institution, enabled }) => {
                if (!enabled) {
                  return null
                }

                return (
                  <Box key={externalId} display="flex" flexDirection="column" gap="8px" mt="16px">
                    <Typography style={{ fontWeight: 500, color: '#051851' }}>{institution}</Typography>
                    <Typography style={{ color: '#4C5870' }}>{fieldOfStudy}</Typography>
                    <Typography style={{ color: '#4C5870' }}>
                      {translate('graduated')} {endDate?.year}
                    </Typography>
                  </Box>
                )
              })}
            </Grid>
          </>
        )}
        {qualifications && qualifications.length > 10 && (
          <>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid item>
              <SectionHeader
                icon={
                  <StarDocumentIcon
                    fontWeight={aboutSectionTitleFontWeight}
                    sx={{
                      width: '16px',
                      fill: 'none'
                    }}
                  />
                }
                gutterBottom
              >
                {translate('profile.qualifications')}
              </SectionHeader>
              <Box
                sx={{
                  span: {
                    p: {
                      strong: {
                        fontSize: '16px'
                      },
                      fontSize: '14px'
                    }
                  },
                  ul: theme.custom.QualificationsSection
                }}
              >
                <HTMLSlot>{qualifications}</HTMLSlot>
              </Box>
            </Grid>
          </>
        )}
      </Box>
      <Grid container flexDirection="column" width="100%">
        {publications && !!publications.length && (
          <>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid container flexDirection="column">
              <SectionHeader
                icon={<PublicationsIcon sx={{ width: '16px', fill: 'none' }} />}
                fontWeight="600"
                onClickChip={handleShowMorePublications}
                label={publications.length > 4 ? `+${publications.length}` : undefined}
              >
                {translate('profile.about.publications')}
              </SectionHeader>
              <Typography color="text.black900" mt="16px">
                {translate('profile.about.readPublicationsBySpecialist')}
              </Typography>
              <Grid mt="32px" container flexDirection="column">
                {publications.slice(0, showMorePublications ? publications.length : 4).map((publication, index) => (
                  <>
                    <PublicationWrapper
                      key={`publication-${index}`}
                      href={publication.link}
                      rel={`noopener ${
                        NOFOLLOW_EXCEPTION.some((exceptionPart) => publication.link.includes(exceptionPart))
                          ? ''
                          : ' nofollow'
                      }`}
                      isFirst={index === 0}
                      isLast={index === publications.length - 1}
                      target="_blank"
                      sx={{ textDecoration: 'none' }}
                    >
                      <Grid direction="column" container>
                        <Typography
                          color="text.secondary"
                          variant="body2"
                          sx={{ fontSize: '11px', lineHeight: '14px', textTransform: 'uppercase' }}
                        >
                          {dayjs(publication.date || publication.createdAt).format('DD / MM / YYYY')}
                        </Typography>
                        <Typography color="text.darkNavy" fontWeight={500} my="8px">
                          {publication.title}
                        </Typography>
                        <Typography
                          color="text.secondary"
                          variant="body2"
                          sx={{ fontSize: '13px', lineHeight: '20px' }}
                        >
                          {translate('profile.about.publishedIn', { site: publication.name || '' })}
                        </Typography>
                      </Grid>
                    </PublicationWrapper>
                  </>
                ))}
              </Grid>
            </Grid>
          </>
        )}
        {!showMorePublications && publications?.length > 4 && (
          <ToggleButton
            sx={{ fontFamily: 'Poppins', padding: 0, marginTop: '24px' }}
            onClick={handleShowMorePublications}
          >
            {translate('profile.about.seeMorePublications')}
          </ToggleButton>
        )}
      </Grid>
      <Box id="fees">
        {(!!languages?.length || visibilityComponents.fees) && (
          <>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" spacing={2}>
                {!!languages?.length && (
                  <Grid item md={6} xs={12}>
                    <SectionHeader fontWeight={aboutSectionTitleFontWeight}>
                      {translate('profile.languages_spoken')}
                    </SectionHeader>
                    <ExpandableList items={languages} valueKey="name" hideBullets={!isWPA} />
                  </Grid>
                )}
                {visibilityComponents.fees && (
                  <Grid item md={6} xs={12}>
                    <SectionHeader>{translate('profile.consultation_fees')}</SectionHeader>
                    <List>
                      {!!consultationFees?.new && (
                        <li style={{ listStyleType: 'none', padding: 0 }}>
                          {translate('profile.new_appointment_price', {
                            price: `${currencySymbol(consultationFees.currency || '', language)}${consultationFees.new}`
                          })}
                        </li>
                      )}
                      {!!consultationFees?.followUp && (
                        <li style={{ listStyleType: 'none', padding: 0 }}>
                          {translate('profile.follow_up_price', {
                            price: `${currencySymbol(consultationFees.currency || '', language)}${
                              consultationFees.followUp
                            }`
                          })}
                        </li>
                      )}
                    </List>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
        {visibilityComponents.insurers && (
          <>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid item>
              <SectionHeader gutterBottom>{translate('profile.insurers')}</SectionHeader>
              <ExpandableList
                items={insurers}
                valueKey="name"
                displayAll={!isMobile}
                columns={3}
                hideBullets
                noSpacing
                largeSpacing
                darkText
              />
            </Grid>
          </>
        )}
        {!!healthChecks?.length && locale !== TENANTS.WPA && (
          <>
            <Grid item>
              <StyledDivider />
            </Grid>
            <Grid item alignItems="center" sx={{ mt: 4, mb: 1 }}>
              <Typography textTransform="uppercase" variant="body2" component="h3" gutterBottom color="text.darkNavy">
                {translate('treatments_and_fees')}
              </Typography>
              <PracticeHealthChecks healthChecks={healthChecks} />
            </Grid>
          </>
        )}
      </Box>
    </Grid>
  )
}

export default ProfileAbout
