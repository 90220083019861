import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTheme, useMediaQuery } from '@hermes/web-components'
import DefaultVariant from './variants/default'
import MinimalVariant from './variants/minimal'
import { RatingBarProps } from './types'
import { AppDataContext } from '../../providers/AppData'
import { TENANTS } from '../../constants/global'
import useTrackWindowHeight from '../../hooks/useTrackWindowHeight'
import FullWidthContainer from '../FullWidthContainer'
import { RootContainer, RootWrapper } from './styles'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'

function RatingBar(props: RatingBarProps) {
  const { endAnchorRef, styles, omitContainerSpacing } = props
  const { locale } = useContext(AppDataContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { newPersistentPanelEnabled } = useFeatureFlags()

  const el = useRef<HTMLDivElement>(null)
  const [elementOffset, setElementOffset] = useState(70)

  const windowHeight = useTrackWindowHeight()

  const ratingBarWrapperHeight = useMemo(() => {
    if (endAnchorRef?.current) {
      return `${endAnchorRef?.current.getBoundingClientRect().top + window.pageYOffset - 200}px`
    }
    return windowHeight
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endAnchorRef?.current, windowHeight])

  useEffect(() => {
    const calculateElementPosition = () => {
      const elementHeight = el.current?.offsetHeight || 0
      setElementOffset(Math.min(window.innerHeight - elementHeight, 70))
    }

    window.addEventListener('resize', calculateElementPosition)
    window.addEventListener('load', calculateElementPosition)
    return () => {
      window.removeEventListener('resize', calculateElementPosition)
      window.removeEventListener('load', calculateElementPosition)
    }
  }, [])

  const containerSx = useMemo(() => ({ backgroundColor: { xs: 'background.default' } }), [])

  if (locale === TENANTS.WPA) {
    return (
      <FullWidthContainer mobileOnly sx={containerSx}>
        <RootContainer height={ratingBarWrapperHeight} sx={styles}>
          <RootWrapper ref={el} elementOffset={elementOffset}>
            <DefaultVariant {...props} />
          </RootWrapper>
        </RootContainer>
      </FullWidthContainer>
    )
  }
  if (newPersistentPanelEnabled) {
    const hideBackground = isMobile && (props.isUnclaimed || !props.latestReview?.id)

    return hideBackground ? (
      <MinimalVariant {...props} />
    ) : (
      <FullWidthContainer mobileOnly sx={containerSx} containerSx={{ padding: '0px !important' }}>
        <RootContainer height={ratingBarWrapperHeight} sx={styles}>
          <RootWrapper
            sx={{
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              padding: omitContainerSpacing ? {} : { sm: '0 25px', xs: '0 0 14px 0' }
            }}
            ref={el}
            elementOffset={elementOffset}
          >
            <MinimalVariant {...props} />
          </RootWrapper>
        </RootContainer>
      </FullWidthContainer>
    )
  }

  return (
    <FullWidthContainer mobileOnly sx={containerSx}>
      <RootContainer height={ratingBarWrapperHeight} sx={styles}>
        <RootWrapper ref={el} elementOffset={elementOffset}>
          <DefaultVariant {...props} />
        </RootWrapper>
      </RootContainer>
    </FullWidthContainer>
  )
}

export type { RatingBarProps, TopKeywordType } from './types'

export default RatingBar
