import { ProfilePeerRecommendation } from '../../../types'
import { Grid, Typography } from '@hermes/web-components'
import Avatar from '../../Avatar'
import getImageWithWWW from '../../../utils/getImageWithWWW'

const RecommenderComponent = ({
  recommender,
  language
}: {
  recommender: ProfilePeerRecommendation['recommender']
  language: string
}) => {
  const { title, firstName, lastName } = recommender

  const userTitle = `${title[language] || title.en || ''} ${firstName[language]} ${lastName[language]}`

  return (
    <Grid mt={1} container alignContent="center" alignItems="center">
      <Avatar
        alt={userTitle}
        width={24}
        height={24}
        src={getImageWithWWW(recommender.images?.logo)}
        withDoctifyCDNLoader
      />
      <Typography ml={1} variant="body2" fontWeight="500" color="text.primary">
        {userTitle}
      </Typography>
    </Grid>
  )
}

export default RecommenderComponent
