import React, { ReactNode } from 'react'
import { Grid, Typography, DonutChart } from '@hermes/web-components'
import ExpandableList from './ExapndableList'
import useTranslateMessage from '../hooks/useTranslateMessage'
import { Statistic } from '../types/statistics'
import useBuildTopStats from '../components/RatingBar/hooks/useBuildTopStats'

type AreasOfExpertiseChartProps = {
  statistic: Statistic[]
  title?: ReactNode
  activeName?: string
}

const AreasOfExpertiseChart = ({ statistic = [], title }: AreasOfExpertiseChartProps) => {
  const translate = useTranslateMessage()
  const {
    stats: { topStats, allItems }
  } = useBuildTopStats(statistic)

  return (
    <Grid
      container
      sx={(theme) => ({
        gap: '94px',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
          gap: '40px',
          flexDirection: 'column'
        }
      })}
    >
      <Grid item sm={7} xs={12}>
        {title}
        <Typography marginTop="4px" variant="body2" color="text.black900">
          {translate('profile.based_on_peer_recommendations_and_reviews')}
        </Typography>
        <ExpandableList noSpacing largeFont largeSpacing limit={3} items={allItems} valueKey="name" />
      </Grid>
      <Grid
        item
        sx={(theme) => ({
          marginRight: '24px',
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center'
          }
        })}
      >
        <DonutChart width={246} height={246} thickness={9} data={topStats} />
      </Grid>
    </Grid>
  )
}

export default AreasOfExpertiseChart
