import {
  CalendarOutlinedIcon,
  EmailOutlinedIcon,
  EventAvailableIcon,
  LocalPhoneIcon,
  PhoneIconAlt,
  QuestionAnswerIcon,
  useMediaQuery,
  useTheme
} from '@hermes/web-components'
import { useContext, useMemo } from 'react'
import { TENANTS } from '../../../constants/global'
import { AppDataContext } from '../../../providers/AppData'
import { RatingBarProps } from '../types'
import { RATING_TYPE } from '../constants'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'

const useRatingBarConfig = ({
  isUnclaimed,
  isBasic,
  reviewsTotal,
  peerRecommendationsCount,
  type,
  bookingCalendarComponent
}: Partial<RatingBarProps> = {}) => {
  const { locale } = useContext(AppDataContext)
  const theme = useTheme()

  const isClinic = type === RATING_TYPE.CLINIC
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'))

  const { newPersistentPanelEnabled } = useFeatureFlags()

  const phoneIcon = useMemo(() => {
    if (locale === TENANTS.WPA || newPersistentPanelEnabled) {
      return <PhoneIconAlt />
    } else {
      return <LocalPhoneIcon />
    }
  }, [locale, newPersistentPanelEnabled])

  const emailIcon = useMemo(() => {
    if (locale === TENANTS.WPA) {
      return <EmailOutlinedIcon />
    } else {
      return <QuestionAnswerIcon />
    }
  }, [locale])

  const externalBookingLinkIcon = useMemo(() => {
    if (locale === TENANTS.WPA || newPersistentPanelEnabled) {
      return <CalendarOutlinedIcon />
    } else {
      return <EventAvailableIcon />
    }
  }, [locale, newPersistentPanelEnabled])

  const displayNewBadge = useMemo(
    () => isUnclaimed || (!reviewsTotal && !peerRecommendationsCount),
    [isUnclaimed, reviewsTotal, peerRecommendationsCount]
  )

  const displayEndorsementsChart = useMemo(() => {
    if (locale === TENANTS.WPA) {
      return !(isBasic || isUnclaimed) && !isClinic
    } else {
      return !(isBasic || isUnclaimed)
    }
  }, [locale, isBasic, isUnclaimed, isClinic])

  const displayRecentReview = useMemo(() => {
    if (locale === TENANTS.WPA) {
      return !bookingCalendarComponent || isLaptop
    } else {
      return true
    }
  }, [locale, isLaptop, bookingCalendarComponent])

  return {
    phoneIcon,
    emailIcon,
    externalBookingLinkIcon,
    displayNewBadge,
    displayEndorsementsChart,
    displayRecentReview
  }
}

export default useRatingBarConfig
