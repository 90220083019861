import { Grid, Link, Typography, useTheme } from '@hermes/web-components'
import useTranslateMessage from '../../../hooks/useTranslateMessage'

type ReviewStatsProps = {
  reviewsTotal: number
  averageRating: number
  peerRecommendationsCount?: number
  hideRealData?: boolean
}

const ReviewStats = ({
  reviewsTotal,
  averageRating,
  peerRecommendationsCount,
  hideRealData = false
}: ReviewStatsProps) => {
  const translate = useTranslateMessage()

  const theme = useTheme()

  return (
    <Grid
      container
      alignItems="center"
      width="100%"
      justifyContent="space-between"
      sx={{ py: 2, px: { xs: 0, lg: 2 }, pb: 0, pt: 1 }}
      wrap="nowrap"
    >
      <Typography variant="h1" component="p" sx={{ lineHeight: 1 }}>
        {hideRealData || !averageRating ? '--' : averageRating.toFixed(2)}
      </Typography>
      <Grid width="max-content" container flexDirection="column" justifyContent="end">
        {!!reviewsTotal && !hideRealData && (
          <Link underline="none" color="#051851" href="#reviews" variant="body2">
            <Typography component="span" sx={theme.custom.SpecialistPage.ratingBar.trustScoreInfo.count}>
              {reviewsTotal}
            </Typography>{' '}
            <Typography component="span" sx={theme.custom.SpecialistPage.ratingBar.trustScoreInfo.infoText}>
              {translate('patient_reviews', { count: reviewsTotal })}
            </Typography>
          </Link>
        )}
        {!!peerRecommendationsCount && (
          <Link underline="none" color="#051851" href="#endorsements" variant="body2">
            <Typography component="span" sx={theme.custom.SpecialistPage.ratingBar.trustScoreInfo.count}>
              {peerRecommendationsCount}
            </Typography>{' '}
            <Typography component="span" sx={theme.custom.SpecialistPage.ratingBar.trustScoreInfo.infoText}>
              {translate('peer_endorsements', { count: peerRecommendationsCount })}
            </Typography>
          </Link>
        )}
      </Grid>
    </Grid>
  )
}

export default ReviewStats
