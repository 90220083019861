import { useContext, useMemo } from 'react'
import { TopKeywordType } from '../types'
import { ChipVariants, useTheme } from '@hermes/web-components'
import { AppDataContext } from '../../../providers/AppData'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { SearchReviewsContext } from '../../../providers/SearchReviews'
import { Statistic } from '../../../types'

const useBuildTopStats = (statistic: Statistic[]) => {
  const { language } = useContext(AppDataContext)
  const {
    state: { filters },
    changeKeyword
  } = useContext(SearchReviewsContext)

  const theme = useTheme()
  const translate = useTranslateMessage()

  const variants: ChipVariants[] = ['aqua', 'coral', 'lightBlue', 'blue']
  const statColors = theme.custom?.common?.ratingBarChartColors || []
  const otherValue = translate('others')

  const statHover = useMemo(
    () => [
      theme.palette.system.aqua,
      theme.palette.system.coral400,
      theme.palette.system.lightBlue600,
      theme.palette.blue[200]
    ],
    [theme]
  ) as string[]

  const transformedStatistic = useMemo(() => {
    const filtered = [...statistic].filter((item) => item.tagKeyword !== 'specialty')
    const sorted = [...filtered].sort((a, b) => b.tagCount - a.tagCount)
    const allItems = sorted.map((stat, idx) => ({
      ...stat,
      name: `${stat.tagName[language]} (${stat.tagCount})`,
      keyName: stat.tagName[language],
      value: stat.tagCount,
      isExpandButton: false,
      color: statColors[idx] || statColors[statColors.length - 1]
    }))
    const othersCount = sorted.slice(3).reduce((prev, curr) => prev + curr.tagCount, 0)
    const topItems = allItems.slice(0, 3)
    if (othersCount) {
      const expandItems = {
        ...topItems[0],
        name: `${translate('others')} (${othersCount})`,
        keyName: translate('others'),
        value: othersCount,
        isExpandButton: true,
        color: statColors[statColors.length - 1]
      }

      allItems.push(expandItems)
      topItems.push(expandItems)
    }

    return {
      topItems,
      allItems
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistic])

  const stats = useMemo(() => {
    const top3Stat = (transformedStatistic.topItems.slice(0, 3) || []).map((stat) => ({
      value: stat.tagCount,
      name: stat.tagName[language]
    }))

    const topTotal = top3Stat.reduce((prev: number, curr) => prev + curr.value, 0)
    const total = statistic.reduce((prev: number, curr) => prev + curr.tagCount, 0)

    const topStatsList: TopKeywordType[] = transformedStatistic.topItems.map((item, idx) => ({
      value: item.value,
      name: item.keyName,
      hover: statHover[idx],
      variant: variants[idx],
      color: String(item.color)
    }))

    return {
      topStats: topStatsList,
      totalStatCount: total,
      topStatCount: topTotal,
      allItems: transformedStatistic.allItems
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistic])

  const onSelectKeyword = (value: string) =>
    changeKeyword(value === otherValue || value === filters.keyword ? '' : value)

  return {
    stats,
    onSelectKeyword
  }
}

export default useBuildTopStats
