import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import { theme } from '../../theme/index.esm.js';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const CarouselArrow = (_a) => {
    var { color, rotation } = _a, props = __rest(_a, ["color", "rotation"]);
    return (React__default.createElement(SvgIcon, Object.assign({ viewBox: "0 0 17 18" }, props, { sx: {
            transform: `rotate(${rotation}deg)`
        } }),
        React__default.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.59233 2.40768L7.59 2.41L13.17 8L1 8C0.447716 8 9.24263e-08 8.44772 9.90123e-08 9C1.05598e-07 9.55229 0.447716 10 1 10L13.17 10L7.59 15.59L7.59186 15.5919C7.39739 15.7737 7.27585 16.0326 7.27585 16.3198C7.27585 16.8701 7.72195 17.3162 8.27224 17.3162C8.56187 17.3162 8.82264 17.1926 9.00469 16.9953L17 9L9.03428 1.03429C8.85171 0.827455 8.5846 0.697006 8.28703 0.697006C7.73673 0.697006 7.29063 1.14311 7.29063 1.6934C7.29063 1.97352 7.40623 2.22665 7.59233 2.40768Z", fill: color || theme.palette.primary.main })));
};

export { CarouselArrow as default };
