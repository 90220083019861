import { styledComponent } from '@hermes/web-components'
import { StyledRevealsContainer } from '../../../../styles'

export const ButtonsPanelWrapper = styledComponent(StyledRevealsContainer)(({ theme }) => ({
  padding: '12px 14px',

  [theme.breakpoints.up('lg')]: {
    margin: '0 -25px',
    width: 'calc(100% + 50px)',
    padding: '11px 25px'
  }
}))
